.ant-select {
  color: rgba(0, 0, 0, 0.85);
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.85);
}

.ant-picker {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.ant-picker-input > input {
  color: rgba(0, 0, 0, 0.85);
}

.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input[disabled]{
  color: rgba(0, 0, 0, 0.85);
}

.ant-input-affix-wrapper > input.ant-input {
  color: rgba(0,0,0, 0.85);
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: rgba(26, 49, 68, 1);
  color:rgba(255, 255, 255, 1);
}

.ant-menu-dark .ant-menu-item, 
.ant-menu-dark .ant-menu-item-group-title, 
.ant-menu-dark .ant-menu-item > a, 
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 1);
}

/* .anticon {
  font-size: 18px !important;
} */

.react-trello-board {
  background-color: transparent !important;
}

.react-trello-board > div,
.react-trello-board > div > .smooth-dnd-container,
.react-trello-board > div > .smooth-dnd-container > .react-trello-lane,
.react-trello-board > div > .smooth-dnd-container > .react-trello-lane > div,
.react-trello-board
  > div
  > .smooth-dnd-container
  > .react-trello-lane
  > div
  > .smooth-dnd-container {
  height: 100%;
  white-space: nowrap;
}

.react-trello-lane {
  background-color: rgb(240, 240, 240) !important;
  margin: 3px;
  padding: 10px 6px;
  border-radius: 10px !important;
}

.react-trello-lane > header > span {
  padding: 5px 0px;
  width: 100%;
  text-align: center;
}

.react-trello-lane > div {
  width: 100%;
}

.smooth-dnd-container > section > div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.smooth-dnd-container > section > div::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.smooth-dnd-container > section > div::-webkit-scrollbar-thumb {
  background-color: #777;
}

.ant-modal-header {
  padding: 15px 10px;
}

.ant-modal-footer {
  padding: 10px 10px;
}

.ant-modal-footer button + button {
  margin-left: 20px;
}

.ant-modal-body {
  padding: 10px;
}

/* .ant-checkbox {
  position: absolute;
}

.ant-checkbox-inner {
  width: 35px;
  height: 35px;
  border-radius: 20px;
}

.ant-checkbox + span {
  position: relative;
  padding-left: 3px;
}

.ant-checkbox-wrapper {
  margin: 5px !important;
  padding-bottom: 5px !important;
}

.ant-badge-status-dot {
  width: 20px;
  height: 20px;
} */